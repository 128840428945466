import './hero.css'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { PopupButton } from 'react-calendly';



export default function HeroSection(){

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return(
      <>
       <div id="hero-section" className='container fill mw-100'>
        <div>
           <div className='container'>
           <div className="row hero text-white">
               <div className="col-lg-12 col-md-12 hero-text"  data-aos="zoom-in-up" data-aos-duration="1000">
                   <h1 className='display-1 px-2 fw-bold'>Barcias Digital Marketing Services</h1>
                   <p className='lead fw-bold'>Helping small and medium size businesses with all their digital and branding needs!</p>
                   <PopupButton className='btn btn-lg mt-2 btn-danger'
                      url="https://calendly.com/barciastech/30min"
                      /*
                       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                       */
                      rootElement={document.getElementById("root")}
                      text="Schedule a call today!"
                     />
                   {/* <a href='#contact' className='btn btn-lg mt-2 btn-danger'>Schedule a call today!</a> */}
               </div>
           </div>
           </div>
        </div>
       </div>
      </>
    )
}